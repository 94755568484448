export const servicesList = [
 
 
  {
    id: 3,
    title: "Full Stack Development",
    description:
      "The online presence helps any business to open ample opportunities for its growth. Based on your individual needs, we can help you to build a modern web solution.",
  },

  {
    id: 4,
    title: "Project Maintanence",
    description:
      "We make sure that your website runs reliably, securely, and blazing fast so that you can focus on what matters, which is growing your business. Buildfast can also keep your website up to date with fresh content and features.",
  },
];
