import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const form = useRef(null);

  

  useEffect(() => {
    if (formSubmitted && form.current) {
      setTimeout(() => {
        form.current.reset();
        setFormSubmitted(false);
      }, 3000);
    }
  }, [formSubmitted]);

  const item = {
    hidden: {
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1 },
    },
    visible: {
      y: 0,
      transition: {
        ease: [0.455, 0.03, 0.515, 0.955],
        duration: 1,
        delay: 1,
      },
    },
  };

  return (
    <div id="form-container" className="lg:p-5">
      <div className="overflow-hidden">
        <motion.h3
          variants={item}
          className="text-center lg:text-5xl text-5xl mt-10 lg:mt-0 pb-4"
        >
          Get In Touch
        </motion.h3>
      </div>
    
      <motion.div
        transition={{ duration: 0.5, delay: 1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {formSubmitted ? (
          <motion.div
            transition={{ duration: 0.5, delay: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <p className="text-center text-cool-green text-lg lg:mt-40 mt-20 mb-20">
              Thank you for your message! We&apos;ll get back to you shortly!
            </p>
          </motion.div>
        ) : (
     <></>
        )}
      </motion.div>
    </div>
  );
};

export default ContactForm;
